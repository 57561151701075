<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import { compactArray, DELAY, STORE_REQUEST_STATUS } from '@emobg/web-utils';

import { MuiAlgoliaList } from '@emobg/vue-internal';

import { aclService } from '@emobg/access-utils';

import AddEmployeeBadgeComponent from '@/components/EmployeeList/modal/AddEmployeeBadge/AddEmployeeBadgeComponent';
import RemoveKeyCard from '@/components/EmployeeList/modal/RemoveKeyCard/RemoveKeyCard';
import EditLicenseExpirationDate from '@/components/EmployeeList/modal/EditLicenseExpirationDate/EditLicenseExpirationDate';
import InvitePeopleModal from '@/components/EmployeeList/modal/InvitePeople/InvitePeople.vue';
import DeactivateUser from '@/components/EmployeeList/modal/DeactivateUser/DeactivateUser';

import { dedicatedFleetCSOperatorUuid, parentCSOperatorUuid } from '@/stores/User/UserData/UserDataMapper';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import {
  companyUuid,
  deactivateUserStatus,
  putAddEmployeeBadge,
  putCancelEmployeeBadge,
  putToggleActiveEmployee,
} from '@/stores/Company/CompanyMapper';

import { EMPLOYEE_STATUS } from '@/constants/employeeStatus';
import {
  ACTIVATE_DEACTIVATE_EMPLOYEE,
  INVITE_EMPLOYEES,
} from '@/constants/permissions';

import { DEFAULT_HITS_PER_PAGE } from '@/constants/algoliaTable';
import { errorNotification } from '@/handlers/errorHandler.const';

import { GTM_MODAL_EVENTS } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useAlgolia } from '@/composable/Algolia/useAlgolia';

import { RESTRICTIVE_KEYCARD_STATUS } from './constants/restrictiveKeycardStatus.const';
import { LABELS } from './constants/employeeList.const';
import { getEmployeesTableFilter, getFacetsConfigs, getTableConfig } from './helpers/employeeList';

const STATUS_CONFIG_ACTION_TEXT = 'actionText';
const STATUS_CONFIG_ACTION_METHOD = 'actionMethod';
const ENDPOINT_ACTIVATE_VALUE = 0;
const ENDPOINT_DEACTIVATE_VALUE = 1;

export default {
  name: 'EmployeeListTab',

  components: {
    InvitePeopleModal,
    AddEmployeeBadgeComponent,
    DeactivateUser,
    EditLicenseExpirationDate,
    MuiAlgoliaList,
    RemoveKeyCard,
  },

  setup() {
    const {
      apiKey,
      algoliaConfig,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
    } = useAlgolia();
    const { trackModalView, trackPageView } = useTrackingGTM();
    const { notifyError, notifySuccess } = useNotifications();
    const app = document.getElementById('app');
    return {
      apiKey,
      algoliaConfig,
      trackModalView,
      trackPageView,
      notifyError,
      notifySuccess,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
      app,
      LABELS,
    };
  },

  data() {
    return {
      employeeList: null,
      loaded: false,
      isListLoading: false,
      editLicenseExpirationDateModal: {
        isOpen: false,
        userUuid: '',
      },
      invitePeopleModal: {
        isOpen: false,
      },
      addEmployeeBadgeModal: {
        isOpen: false,
        csOperatorUuid: '',
        userUuid: '',
      },
      deactivateUserModal: {
        isOpen: false,
        userUuid: '',
      },
      removeKeyCardModal: {
        isOpen: false,
        initialStatus: STORE_REQUEST_STATUS.idle,
        userUuid: '',
        badgeDeliveryUuid: '',
      },
    };
  },

  computed: {
    dedicatedFleetCSOperatorUuid,
    companyUuid,
    deactivateUserStatus,
    parentCSOperatorUuid,
  },

  async created() {
    const csOperatorUuid = this.dedicatedFleetCSOperatorUuid || this.parentCSOperatorUuid;
    const csOperator = await this.fetchCSOperator(csOperatorUuid);

    this.ALGOLIA_TABLE_FILTER = getEmployeesTableFilter({ companyUuid: this.companyUuid });
    await this.initAlgolia('employees', this.ALGOLIA_TABLE_FILTER);
    this.TABLE_LABELS = this.getAlgoliaLabels(this.LABELS);

    const {
      dl_review_days: csOperatorUsesDrivingLicenseReview,
      restrictive_badge_provider_id: isCsOperatorUsingRestrictiveBadgeProvider,
      badge_required: isCsOperatorUsingBadges,
    } = csOperator.configuration;
    // Row actions
    const EMPLOYEE_STATUS_ITEM_ACTION = {
      label: ({ dedicated_fleet_status: status }) => get(
        this.getEmployeeStatusConfig(status),
        STATUS_CONFIG_ACTION_TEXT,
      ),
      method: context => get(
        this.getEmployeeStatusConfig(context.dedicated_fleet_status),
        STATUS_CONFIG_ACTION_METHOD,
      )(context),
      isVisible: context => this.canUpdateEmployee(context),
      class: 'emobg-color-danger',
    };

    const DRIVING_LICENSE_REVIEW_ITEM_ACTION = {
      label: this.$t('business_profile.action.edit_license_expiration_date'),
      method: this.openEditLicenseModal,
      isVisible: context => this.canUpdateEmployee(context)
        && csOperatorUsesDrivingLicenseReview,
    };

    const CUSTOM_BADGE_ITEM_ACTION = {
      label: ({ badge_status: badgeStatus }) => get(
        this.getKeycardStatusConfig(badgeStatus),
        STATUS_CONFIG_ACTION_TEXT,
      ),
      method: context => get(
        this.getKeycardStatusConfig(context.badge_status),
        STATUS_CONFIG_ACTION_METHOD,
      )(context, csOperator),
      isVisible: context => this.canUpdateEmployee(context)
        && isCsOperatorUsingBadges && isCsOperatorUsingRestrictiveBadgeProvider,
    };

    // Table actions
    const INVITE_EMPLOYEE_ACTION
      = aclService.hasPermissions(INVITE_EMPLOYEES) && {
        label: this.$t('business_profile.action.add_employee'),
        action: () => {
          this.openInvitePeopleModal();
          this.trackModalView({ modalName: GTM_MODAL_EVENTS.inviteEmployees });
        },
      };

    this.ITEMS_ACTIONS = compactArray([
      DRIVING_LICENSE_REVIEW_ITEM_ACTION,
      CUSTOM_BADGE_ITEM_ACTION,
      EMPLOYEE_STATUS_ITEM_ACTION,
    ]);

    this.TABLE_CONFIG = filter(getTableConfig(
      {
        csOperatorUsesDrivingLicenseReview,
        isCsOperatorUsingBadges,
        isCsOperatorUsingRestrictiveBadgeProvider,
        rowActions: this.ITEMS_ACTIONS,
      },
    ), 'isVisible');

    this.TABLE_FACETS = filter(getFacetsConfigs({
      isCsOperatorUsingBadges,
      isCsOperatorUsingRestrictiveBadgeProvider,
    }), 'isVisible');

    this.TABLE_ACTIONS = compactArray([
      INVITE_EMPLOYEE_ACTION,
    ]);

    this.ALGOLIA_QUERY_PARAMETERS = {
      hitsPerPage: DEFAULT_HITS_PER_PAGE,
    };

    this.loaded = true;
  },

  methods: {
    fetchCSOperator,
    putToggleActiveEmployee,
    putCancelEmployeeBadge,
    putAddEmployeeBadge,

    getEmployeeStatusConfig(status) {
      switch (status) {
        case EMPLOYEE_STATUS.activated:
          return {
            [STATUS_CONFIG_ACTION_TEXT]: this.$t('business_profile.action.desactivate'),
            [STATUS_CONFIG_ACTION_METHOD]: ({ uuid: userUuid }) => {
              this.deactivateUserModal.userUuid = userUuid;
              this.deactivateUserModal.isOpen = true;
            },
          };
        case EMPLOYEE_STATUS.blocked:
        default:
          return {
            [STATUS_CONFIG_ACTION_TEXT]: this.$t('business_profile.action.activate'),
            [STATUS_CONFIG_ACTION_METHOD]: async context => {
              await this.deactivateUserAction(context, true);
            },
          };
      }
    },

    getKeycardStatusConfig(status) {
      switch (status) {
        case RESTRICTIVE_KEYCARD_STATUS.enabled:
          return {
            [STATUS_CONFIG_ACTION_TEXT]: this.$t('business_profile.action.remove_keycard'),
            [STATUS_CONFIG_ACTION_METHOD]: ({ uuid: userUuid, badge_delivery_uuid: badgeDeliveryUuid }) => {
              this.removeKeyCardModal.userUuid = userUuid;
              this.removeKeyCardModal.badgeDeliveryUuid = badgeDeliveryUuid;
              this.removeKeyCardModal.isOpen = true;
              this.trackModalView({ modalName: GTM_MODAL_EVENTS.editEmployeeBadge });
            },
          };

        case RESTRICTIVE_KEYCARD_STATUS.missing:
          return {
            [STATUS_CONFIG_ACTION_TEXT]: this.$t('business_profile.action.add_keycard'),
            [STATUS_CONFIG_ACTION_METHOD]: ({ uuid: userUuid }, { uuid: csOperatorUuid }) => {
              this.addEmployeeBadgeModal.userUuid = userUuid;
              this.addEmployeeBadgeModal.csOperatorUuid = csOperatorUuid;
              this.addEmployeeBadgeModal.isOpen = true;
              this.trackModalView({ modalName: GTM_MODAL_EVENTS.editEmployeeBadge });
            },
          };
        default:
          return {
            [STATUS_CONFIG_ACTION_TEXT]: this.$t('business_profile.action.add_keycard'),
            [STATUS_CONFIG_ACTION_METHOD]: () => {
              this.notifyError({
                text: this.$t('notifications.whooops'),
              });
            },
          };
      }
    },

    closeRemoveKeyCardModal() {
      this.removeKeyCardModal.isOpen = false;
    },

    async deactivateUserAction(context = null, activate = false) {
      await this.putToggleActiveEmployee({
        employeeUuid: get(context, 'uuid', this.deactivateUserModal.userUuid) || this.deactivateUserModal.userUuid,
        data: {
          deactivate: activate ? ENDPOINT_ACTIVATE_VALUE : ENDPOINT_DEACTIVATE_VALUE,
        },
      });

      if (this.deactivateUserStatus.ERROR) {
        this.notifyError(errorNotification);
      } else {
        this.isListLoading = true;
        this.notifySuccess({
          text: this.$t(activate ? 'business_profile.employee.modal_success_activated' : 'business_profile.employee.modal_success_deactivated'),
        });

        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }

      this.deactivateUserModal.isOpen = false;
    },

    async removeKeyCardModalAction() {
      const { userUuid, badgeDeliveryUuid } = this.removeKeyCardModal;

      try {
        await this.putCancelEmployeeBadge({ employeeUserUuid: userUuid, badge: { badgeDeliveryUuid } });
        this.notifySuccess({
          text: this.$t('business_profile.employee.modal_confirm_remove_keycard_success'),
        });
      } catch (error) {
        this.notifyError(errorNotification(error));
      }

      this.isListLoading = true;
      setTimeout(() => {
        this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeList, DELAY.medium);
        this.isListLoading = false;
      }, DELAY.medium);

      this.closeRemoveKeyCardModal();
    },

    closeAddEmployeeBadgeModal() {
      this.addEmployeeBadgeModal.isOpen = false;
    },

    openInvitePeopleModal() {
      this.invitePeopleModal.isOpen = true;
    },

    async addEmployeeBadgeModalAcceptFunction({ component }) {
      try {
        if (!component.getBadgeDeliveryUuid()) {
          component.setErrorMessage(this.$t('business_profile.employee.modal_add_keycard_error_message'));
          return;
        }

        const badgeDeliveryUuid = component.getBadgeDeliveryUuid();

        await this.putAddEmployeeBadge({ employeeUserUuid: this.addEmployeeBadgeModal.userUuid, badge: { badgeDeliveryUuid } });

        this.notifySuccess({
          text: this.$t('business_profile.employee.modal_add_keycard_success_text'),
        });
      } catch (error) {
        this.notifyError(errorNotification(error));
      }

      this.isListLoading = true;
      setTimeout(() => {
        this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeList, DELAY.medium);
        this.isListLoading = false;
      }, DELAY.medium);

      this.closeAddEmployeeBadgeModal();
    },

    openEditLicenseModal({ uuid }) {
      this.editLicenseExpirationDateModal.userUuid = uuid;
      this.editLicenseExpirationDateModal.isOpen = true;
      this.trackModalView({ modalName: GTM_MODAL_EVENTS.editEmployeeDrivingLicense });
    },

    closeEditLicenseExpirationDateModal() {
      this.editLicenseExpirationDateModal.isOpen = false;
    },

    editLicenseExpirationDateModalOnSuccess() {
      this.isListLoading = true;
      setTimeout(() => {
        this.refreshAlgoliaTable(this.$refs.muiAlgoliaEmployeeList, DELAY.medium);
        this.isListLoading = false;
      }, DELAY.medium);
      this.closeEditLicenseExpirationDateModal();
    },

    canUpdateEmployee({ circles: uuids }) {
      return aclService.hasUserPermissions(ACTIVATE_DEACTIVATE_EMPLOYEE)
        || aclService.hasPermissions(ACTIVATE_DEACTIVATE_EMPLOYEE, uuids);
    },
  },
};

</script>

<template>
  <div class="container mt-5 pb-4">
    <h2 class="mb-3">
      {{ $t('business_profile.employee.title') }}
    </h2>
    <MuiAlgoliaList
      v-if="loaded"
      ref="muiAlgoliaEmployeeList"
      :loading="isListLoading"
      :config="algoliaConfig"
      :api-key="apiKey"
      :filters="ALGOLIA_TABLE_FILTER"
      :facets="TABLE_FACETS"
      :table-config="TABLE_CONFIG"
      :search-placeholder="TABLE_LABELS.searchTable"
      :query-parameters="ALGOLIA_QUERY_PARAMETERS"
      :labels="TABLE_LABELS"
      :no-data-label="FALLBACK_MESSAGE.dash"
      :scroll-target="app"
      index="employees"
      class="EmployeeListTab"
    >
      <ui-dropdown
        slot="extraAction"
        :size="SIZES.small"
      >
        <ui-button
          slot="trigger"
          :size="SIZES.small"
          :color="GRAYSCALE.inkLight"
          :face="FACES.outline"
          square
        >
          <ui-icon
            :size="ICONS_SIZES.small"
            :color="GRAYSCALE.ink"
            :icon="ICONS.optionsHorizontalFull"
          />
        </ui-button>
        <ui-dropdown-actions
          slot="content"
          :actions.prop="TABLE_ACTIONS"
        />
      </ui-dropdown>
    </MuiAlgoliaList>

    <AddEmployeeBadgeComponent
      v-if="addEmployeeBadgeModal.isOpen"
      :is-open="addEmployeeBadgeModal.isOpen"
      :error="$t('business_profile.employee.modal_add_keycard_error_message')"
      :cs-operator-uuid="addEmployeeBadgeModal.csOperatorUuid"
      :company-uuid="companyUuid"
      :on-success="addEmployeeBadgeModalAcceptFunction"
      :on-cancel="closeAddEmployeeBadgeModal"
    />

    <EditLicenseExpirationDate
      v-if="editLicenseExpirationDateModal.isOpen"
      :user-uuid="editLicenseExpirationDateModal.userUuid"
      :is-open="editLicenseExpirationDateModal.isOpen"
      :on-cancel="closeEditLicenseExpirationDateModal"
      :on-success="editLicenseExpirationDateModalOnSuccess"
    />

    <InvitePeopleModal
      v-if="invitePeopleModal.isOpen"
      :is-open="invitePeopleModal.isOpen"
      :on-cancel="() => invitePeopleModal.isOpen = false"
    />

    <DeactivateUser
      v-if="deactivateUserModal.isOpen"
      :is-open="deactivateUserModal.isOpen"
      :on-cancel="() => deactivateUserModal.isOpen = false"
      :on-success="deactivateUserAction"
    />

    <RemoveKeyCard
      v-if="removeKeyCardModal.isOpen"
      :is-open="removeKeyCardModal.isOpen"
      :initial-status="removeKeyCardModal.initialStatus"
      :on-success="removeKeyCardModalAction"
      :on-cancel="closeRemoveKeyCardModal"
    />
  </div>
</template>
